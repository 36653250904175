import inquirySuccessAnimation from '../lottiefiles/tick.json';
import LottieComp from './lottie';
import styles from './tick.module.css';

export const Tick = () => {
  return (
    <div className={styles.tick}>
      <LottieComp src={JSON.stringify(inquirySuccessAnimation)} />
    </div>
  );
};
