import { useMessages } from '../i18n/useMessages';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

interface EmailInputProps {
  value: string;
  onChange: (value: string) => void;
  appearance?: 'subtle' | 'normal';
}

export const EmailInput: React.FC<EmailInputProps> = ({
  value,
  onChange,
  appearance = 'normal',
}) => {
  const [visible, setVisible] = useState(false);

  const onMount = useCallback(() => {
    const element = document.getElementById('#email-input-placeholder');
    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);

          observer.disconnect();
          observer.unobserve(element);
        }
      },
      { threshold: 0.5 },
    );

    observer.observe(element);
  }, []);

  const isSubtle = appearance === 'subtle';

  const getMessage = useMessages();
  const placeholder = isSubtle
    ? getMessage('camp67-footer.email.placeholder-subtle')
    : getMessage('camp67-footer.email.placeholder');

  if (!visible) {
    return (
      <div
        id="#email-input-placeholder"
        ref={onMount}
        className={clsx({
          'px-6 py-7 bg-white rounded-l-md w-full text-base xl:text-xl placeholder-gray-200 outline-none':
            !isSubtle,
          'bg-transparent px-4 py-3 w-full border-r-2 text-lg border-b-2 border-t-0 border-l-0 outline-none border-b-red-200 border-r-red-200':
            isSubtle,
        })}
      />
    );
  }

  return (
    <input
      id="#email-input"
      value={value}
      className={clsx({
        'px-6 py-7 bg-white rounded-l-md w-full text-base xl:text-xl placeholder-gray-200 outline-none':
          !isSubtle,
        'bg-transparent px-4 py-3 w-full border-r-2 text-lg border-b-2 border-t-0 border-l-0 outline-none border-b-red-200 border-r-red-200':
          isSubtle,
      })}
      type="email"
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};
