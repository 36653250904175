import { Link, useFetcher } from '@remix-run/react';
import { analyticsState, AmplitudeEvent, ButtonTag, Operation } from '../state/amplitude';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDisableBodyScroll } from '../hooks/use-disable-body-scroll';
import { validate as validateEmail } from 'email-validator';
import { EmailInput } from './email-input';
import { ModalCloseButton } from './booking-form/modal-close-button';
import { Modal } from './policy-modal';
import { Tick } from './tick';
import { images } from '../constants/images';
import { Divider } from './divider';
import Sprite from '../sprites/sprite';
import { FACEBOOK, INSTAGRAM, TWITTER, YOUTUBE } from '../constants/socials';
import { FormattedString, useMessages } from '../i18n/useMessages';

interface FooterProps {
  isHome?: boolean;
}

interface SubscriptionResult {
  success: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isHome = true }) => {
  const fetcher = useFetcher();

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const onClose = useCallback(() => setIsSubscriptionModalOpen(false), []);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const onChange = useCallback((value: string) => {
    setEmail(value);
    setError('');
  }, []);
  const onSubmit = useCallback(() => {
    analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonTag: ButtonTag.SUBSCRIBE,
    });

    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      // TODO: sentry?
    } else {
      fetcher.submit(
        { email, intent: 'subscribe' },
        { method: 'post', action: '/?index' },
      );
      setEmail('');
    }
  }, [email, fetcher]);

  useEffect(() => {
    const response = fetcher.data as SubscriptionResult;
    if (response) {
      analyticsState?.amplitude?.track(
        response.success
          ? AmplitudeEvent.OPERATION_SUCCESS
          : AmplitudeEvent.OPERATION_FAIL,
        {
          operationName: Operation.SUBSCRIBE,
        },
      );
      setIsSubscriptionModalOpen(response.success);
    }
  }, [fetcher.data]);

  const isDisabled = email.length === 0;

  const getMessage = useMessages();

  return (
    <>
      <EmailSubscriptionModal isOpen={isSubscriptionModalOpen} onClose={onClose} />
      {isHome && (
        <div id="subscribe" className="text-center mt-12 sm:mt-22">
          <p className="px-12 sm:px-32 md:px-44 lg:px-72">
            <FormattedString message="camp67-footer.stay-informed" />
          </p>
          <div className="flex flex-col w-full px-12 sm:px-32 md:px-44 lg:px-72 m-auto mt-4 sm:mt-7">
            <div className="flex flex-row m-auto w-full border-2 border-red-200 rounded-lg z-10">
              <EmailInput value={email} onChange={onChange} />
              <button
                className={clsx(
                  'bg-red-200 px-4 py-2 md:px-14 md:py-3 rounded-r-md cursor-pointer',
                  {
                    'cursor-auto bg-slate-50': isDisabled,
                  },
                )}
                onClick={onSubmit}
                disabled={isDisabled}
              >
                <span className="text-red-400 text-lg xl:text-base">→</span>
              </button>
            </div>
            <span className="text-left pl-6 pt-4">{error}</span>
          </div>
        </div>
      )}
      <footer
        className={clsx(
          'w-full px-12 pb-6 sm:px-32 md:px-44 lg:px-72 text-gray-500 relative border-t border-dotted border-t-black',
          {
            '-mt-14 bg-yellow-500 pt-20': isHome,
            'bg-yellow-500 border-t-2 border-dashed pt-16': !isHome,
          },
        )}
        id="footer"
      >
        <div className="flex flex-row items-center justify-between w-full">
          <span className="uppercase tracking-wider flex flex-col gap-2 md:block text-sm">
            {getMessage('camp67-header.tagline')}{' '}
            <Link
              prefetch="intent"
              to="/packages/reservation"
              className="text-blue-500 underline font-bold mb-3 sm:mb-0"
            >
              {getMessage('camp67-banner.book-now')} &rarr;
            </Link>
          </span>
          <ul className="hidden sm:flex flex-row items-center gap-1">
            <a href={FACEBOOK} target="_blank" rel="noreferrer">
              <li className="cursor-pointer hover:opacity-75 transition">
                <Sprite
                  name="facebook"
                  width="64"
                  height="64"
                  fill="black"
                  className="w-6 h-6"
                />
              </li>
            </a>
            <a href={INSTAGRAM} target="_blank" rel="noreferrer">
              <li className="cursor-pointer hover:opacity-75 transition">
                <img
                  src="/instagram.png"
                  alt="Instagram logo"
                  className="pointer-events-none select-none w-6 h-6"
                />
              </li>
            </a>
            <a href={TWITTER} target="_blank" rel="noreferrer">
              <li className="cursor-pointer hover:opacity-75 transition">
                <Sprite
                  name="twitter"
                  width="64"
                  height="64"
                  fill="black"
                  className="w-6 h-6"
                />
              </li>
            </a>
            <a href={YOUTUBE} target="_blank" rel="noreferrer">
              <li className="cursor-pointer hover:opacity-75 transition">
                <Sprite
                  name="youtube"
                  width="64"
                  height="45"
                  fill="#b2071d"
                  className="w-6 h-6"
                />
              </li>
            </a>
          </ul>
        </div>
        <p className="text-sm">bookings@camp67.com / +91 8860092457 / +61 478947432</p>
        <Divider className="border border-[#000]/10 my-6" />
        {!isHome && (
          <div
            id="subscribe"
            className="flex flex-col md:flex-row items-center justify-between w-full gap-12 mt-8"
          >
            <div className="w-full z-10">
              <p className="font-serif text-black font-bold text-xl mb-2">
                {getMessage('camp67-footer.subscribe-for-updates')}
              </p>
              <div className="relative w-full max-w-120">
                <EmailInput value={email} onChange={onChange} appearance="subtle" />
                <button
                  onClick={onSubmit}
                  className="absolute right-2 p-3 top-1/2 -translate-y-1/2"
                >
                  &rarr;
                </button>
              </div>
            </div>
            <Link prefetch="intent" className="hidden md:block" to="/">
              <img
                src={images['logo-dark']}
                alt="The camp67 Logo in black"
                className="hidden md:block pointer-events-none select-none"
              />
            </Link>
          </div>
        )}
        <div className="flex flex-col sm:flex-row w-full justify-between text-black mt-8">
          <p className="hidden sm:block w-[95%] max-w-[320px] leading-relaxed">
            <FormattedString
              message="camp67-footer.camp67-summary"
              components={{
                'swt-link': (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://sukritwellness.com"
                    className="text-blue-500 underline"
                  />
                ),
                'ksc-link': (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href="https://kumbhsukrit.com" className="text-blue-500 underline" />
                ),
                'join-us-link': (
                  <Link
                    prefetch="intent"
                    className="text-blue-500 underline"
                    to="/packages/reservation"
                  />
                ),
              }}
            />
          </p>
          <div className="flex flex-col gap-3 sm:text-right">
            <Link
              prefetch="intent"
              to="/the-camp"
              className="hover:underline underline-offset-4"
            >
              {getMessage('camp67-navigation.the-camp')}
            </Link>
            <Link
              prefetch="intent"
              to="/kumbh-2025"
              className="hover:underline underline-offset-4"
            >
              {getMessage('camp67-navigation.kumbh-2025')}
            </Link>
            <Link
              prefetch="intent"
              to="/packages"
              className="hover:underline underline-offset-4"
            >
              {getMessage('camp67-navigation.packages')}
            </Link>
            <Link
              prefetch="intent"
              to="/contact-us"
              className="hover:underline underline-offset-4"
            >
              {getMessage('camp67-navigation.contact-us')}
            </Link>
          </div>
        </div>
        <div className="flex flex-col xs:flex-row w-full gap-2 sm:gap-0 xs:items-center text-left justify-between mt-9">
          <div className="flex flex-col gap-2">
            <Link
              prefetch="intent"
              to="/terms-of-service"
              className="uppercase tracking-wider text-gray-400 font-medium text-xs"
            >
              {getMessage('camp67-link.terms-of-service')}
            </Link>
            <Link
              prefetch="intent"
              to="/privacy-policy"
              className="uppercase tracking-wider text-gray-400 font-medium text-xs"
            >
              {getMessage('camp67-link.privacy-policy')}
            </Link>
          </div>
          <div className="flex flex-col gap-2 xs:items-end">
            <Link
              prefetch="intent"
              to="/payment-policy"
              className="uppercase tracking-wider text-gray-400 font-medium text-xs"
            >
              {getMessage('camp67-link.payment-policy')}
            </Link>
            <Link
              prefetch="intent"
              to="/cancellation-and-refund-policy"
              className="uppercase tracking-wider text-gray-400 font-medium text-xs"
            >
              {getMessage('camp67-link.cancellation-policy')}
            </Link>
          </div>
        </div>
        <div className="flex flex-col xs:flex-row xs:justify-between">
          <small className="block mt-10 xs:mt-8 text-xs font-sans font-light">
            © All Rights Reserved 2024, camp67
          </small>
          <small className="block mt-2 xs:mt-8 text-xs font-sans font-light">
            {getMessage('camp67-footer.a-product-of')}{' '}
            <a href="https://sukritwellness.com" className="text-blue-500 underline">
              Sukrit Wellness Tours
            </a>{' '}
            Pvt Ltd
          </small>
        </div>
      </footer>
    </>
  );
};

export const FooterCompact: React.FC = () => {
  const getMessage = useMessages();

  return (
    <footer
      className={clsx(
        'm-auto w-full xl:px-60 lg:px-24 md:px-16 px-8 py-8 text-gray-500 relative border-t-black bg-yellow-500 border-t-2 border-dashed',
      )}
      id="footer"
    >
      <div className="flex flex-row items-center justify-between w-full">
        <span className="hidden uppercase tracking-wider gap-2 md:block text-sm">
          {getMessage('camp67-header.tagline')}{' '}
          <Link
            prefetch="intent"
            to="/packages/reservation"
            className="text-blue-500 underline font-bold mb-3 sm:mb-0"
          >
            {getMessage('camp67-banner.book-now')} &rarr;
          </Link>
        </span>
      </div>
      <p className="text-sm">bookings@camp67.com / +91 8860092457 / +61 478947432</p>
      <Divider className="border border-[#000]/10 my-6" />
      <div className="flex flex-col xs:flex-row xs:justify-between mt-2">
        <small className="block text-xs font-sans font-light">
          © All Rights Reserved 2024, camp67
        </small>
        <small className="block text-xs font-sans font-light">
          {getMessage('camp67-footer.a-product-of')}{' '}
          <a href="https://sukritwellness.com" className="text-blue-500 underline">
            Sukrit Wellness Tours
          </a>{' '}
          Pvt Ltd
        </small>
      </div>
    </footer>
  );
};

interface EmailSubscriptionModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const EmailSubscriptionModal: React.FC<EmailSubscriptionModalProps> = ({
  isOpen,
  onClose,
}) => {
  useDisableBodyScroll(isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-11/12 md:w-8/12 bg-white pb-10 flex flex-col items-center justify-center h-4/6 rounded-md relative">
        <ModalCloseButton onClose={onClose} />
        <Tick />
        <h2 className="text-4xl font-serif text-red-200 font-semibold text-center w-fit">
          <FormattedString message="camp67-footer.subscribe-success" />
        </h2>
        <p className="mt-4 text-center w-4/6">
          <FormattedString message="camp67-footer.subscribe-success-message" />
        </p>
        <p className="mt-4 text-center w-fit">
          <FormattedString message="camp67-footer.subscribe-opt-out" />
        </p>
      </div>
    </Modal>
  );
};
